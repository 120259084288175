import React from 'react';
import classnames from 'classnames';

import { tagType } from './types';

export default function Tag({ data }) {
  const tagClasses = classnames('event-item', {
    'event-tag': data.isTag,
  });
  return (
    <div className={tagClasses}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <a
        href={data.url}
        dangerouslySetInnerHTML={{
          __html: data.isTag ? `#${data.name}` : data.name,
        }}
      />
    </div>
  );
}
Tag.displayName = 'Tag';
Tag.propTypes = {
  data: tagType.isRequired,
};
