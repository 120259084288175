import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Post from './Post';
import { postListType } from './types';

export default function PostList({ heading, posts }) {
  const listClasses = classnames(
    'search-results-list',
    `search-list--count-${posts.length}`
  );
  const propsVisible = posts.length > 0;

  const listHeading = classnames(
    'search-results-heading',
    'staff-list-heading',
    {
      'staff-heading-visible': propsVisible,
    }
  );

  return (
    <div className="search-results-module">
      <h2 className={listHeading}>{heading}</h2>
      <div className={listClasses}>
        {posts && posts.map(item => <Post key={item.post_id} data={item} />)}
      </div>
    </div>
  );
}
PostList.displayName = 'PostList';
PostList.propTypes = {
  posts: postListType.isRequired,
  heading: PropTypes.string,
};
PostList.defaultProps = {
  heading: 'Posts',
};
