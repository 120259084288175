import React from 'react';
import PropTypes from 'prop-types';

export default function SearchClose({ onClick, text }) {
  return (
    <div className="search-close search-header-close">
      <button
        type="button"
        className="search-close-button search-button"
        onClick={onClick}
      >
        <div className="search-close-cross">
          <span className="close-cross" />
        </div>
        <span type="button-text">{text}</span>
      </button>
    </div>
  );
}
SearchClose.displayName = 'SearchClose';
SearchClose.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
SearchClose.defaultProps = {
  text: 'Close',
};
