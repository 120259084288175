import React from 'react';
import PropTypes from 'prop-types';

import Tag from './Tag';
import { tagListType } from './types';

export default function TagList({ heading, tags }) {
  return (
    <div className="search-results-module">
      <h2 className="search-results-heading">{heading}</h2>
      <div className="search-results-tags event-categories">
        {!!tags && tags.map(tag => <Tag key={tag.key} data={tag} />)}
      </div>
    </div>
  );
}
TagList.displayName = 'TagList';
TagList.propTypes = {
  heading: PropTypes.string,
  tags: tagListType.isRequired,
};
TagList.defaultProps = {
  heading: 'Categories & Terms',
};
