import React from 'react';
import PropTypes from 'prop-types';

export default class SearchModal extends React.Component {
  handleKeyDown = e => {
    switch (e.key) {
      case 'Escape':
        // eslint-disable-next-line react/destructuring-assignment
        this.props.onClose();
        break;
      default:
        // Empty default
        break;
    }
  };

  render() {
    const { children } = this.props;
    return (
      // Catches bubbling event
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <section className="search-modal" onKeyDown={this.handleKeyDown}>
        <div className="search-box">{children}</div>
      </section>
    );
  }
}
SearchModal.displayName = 'SearchModal';
SearchModal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};
