import PropTypes from 'prop-types';

export const postType = PropTypes.shape({
  post_id: PropTypes.number.isRequired,
  post_title: PropTypes.string.isRequired,
  post_excerpt: PropTypes.string,
  post_image: PropTypes.string,
  pretty_link: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
});

export const dateArr = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.arrayOf(PropTypes.string),
]);

export const personType = PropTypes.shape({
  post_id: PropTypes.number.isRequired,
  post_image: PropTypes.string,
  person_name: PropTypes.string,
  person_title: PropTypes.string,
  person_phone: PropTypes.string,
  person_email: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
});

export const tagType = PropTypes.shape({
  key: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isTag: PropTypes.bool.isRequired,
});

export const personListType = PropTypes.arrayOf(personType);

export const postListType = PropTypes.arrayOf(postType);

export const tagListType = PropTypes.arrayOf(tagType);

export const searchResults = PropTypes.shape({
  persons: personListType,
  posts: postListType,
  tags: tagListType,
});

export const searchResultsType = PropTypes.oneOfType([
  searchResults,
  PropTypes.array,
]);
