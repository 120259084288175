import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

/**
 * Merge objects into a new one.
 *
 * @param {...object} args - Objects to merge.
 * @return {object}
 */
export function assign(...args) {
  return Object.assign({}, ...args);
}

/**
 * Truncate a string if it's longer than the specified limit.
 *
 * @param {string} str - The string to truncate.
 * @param {number} len - Maximum string length.
 * @param {string} [add] - What to add to the end of the string if it's
 *   truncated. Defaults to ellipsis.
 * @return {string}
 */
export function truncateString(str, len, add = '…') {
  if (str.length > len) {
    return str.substring(0, len - add.length) + add;
  }

  return str;
}

/**
 * Get the current page host address.
 *
 * @return {string}
 */
export function getHost() {
  // There is an 'origin' property in the Location object, but it's buggy in IE
  const loc = global.location;

  return `${loc.protocol}//${loc.host}`;
}

/**
 * Get search results from Elasticsearch.
 *
 * @param {string} searchTerm - Term to search for.
 * @param {number} [tagLimit] - Limit the number of returned tags.
 * @return {object}
 */
export async function getSearchResults(searchTerm, tagLimit = 0) {
  const response = await fetch(
    `${getHost()}/search.php?s=${searchTerm}&t=${tagLimit}`,
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Got response ${response.status}`);
  }

  const data = await response.json();

  return data;
}

/**
 * Send an event to Google Analytics.
 *
 * Using the object notation for ga(), eventCategory and eventAction are
 * required. Can optionally pass eventLabel and eventValue.
 *
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/events
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
 * @param {object} data - Data object to send with ga().
 */
export function sendAnalyticsEvent(data = {}) {
  if (!global.ga) {
    return;
  }

  const maxLengths = {
    eventCategory: 150,
    eventAction: 500,
    eventLabel: 500,
  };
  const defaults = {
    eventCategory: 'search',
    eventAction: 'searchTerm',
    eventLabel: null,
    eventValue: null,

    // Use navigator.sendBeacon when supported
    transport: 'beacon',
  };

  // Merge data with defaults and make sure each field is formatted correctly,
  // see field reference
  const processedData = mapValues(defaults, (val, key) => {
    const maxLen = maxLengths[key] || null;

    let dataVal = data[key] || val || null;
    if (key === 'eventValue') {
      dataVal = parseInt(dataVal, 10) || null;
    }

    return typeof dataVal === 'string' && maxLen
      ? truncateString(dataVal, maxLen)
      : dataVal;
  });
  const result = pickBy(processedData, val => val !== null);

  global.ga('send', 'event', result);
}

/**
 * Removes all html tags from string.
 *
 * @param {string} str - The string to strip html from
 * @return {string}
 */
export function stripHtml(str) {
  return str.replace(/<[^>]+>/gm, '');
}

/**
 * Check if date is today or in the future
 *
 * @param {Date} date - The date
 * @return {boolean}
 */
export function isTodayOrFuture(date) {
  const now = new Date();
  const today = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
  );
  today.setHours(0, 0, 0, 0);

  const d = new Date(date);
  d.setHours(0, 0, 0, 0);

  return d.getTime() >= today.getTime();
}

/**
 * Format a date using `toLocaleString` with the document's language.
 *
 * @param {Date} date - The date to format.
 * @param {object} options - The options for `toLocaleString`.
 * @param {string} [locale] - Force another locale.
 * @return {string}
 */
export function localeFormat(date, options, locale = null) {
  // Fall back to English if no lang is set.
  const lang = locale || document.documentElement.lang || 'en';
  // Use English as a fallback in case the specified locale isn't supported.
  return date.toLocaleString([lang, 'en'], options);
}
