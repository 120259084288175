import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Person from './Person';
import { personListType } from './types';

export default function PersonList({ heading, persons }) {
  const propsVisible = persons.length > 0;
  const listClasses = classnames(
    'staff-list',
    'staff-results-list',
    'search-results-list',
    { [`staff-list--count-${persons.length}`]: propsVisible }
  );

  const listHeading = classnames(
    'search-results-heading',
    'staff-results-heading',
    { 'staff-heading-visible': propsVisible }
  );

  return (
    <div className="search-results-module">
      <h2 className={listHeading}>{heading}</h2>
      <div className={listClasses}>
        {persons &&
          persons.map(item => <Person key={item.post_id} data={item} />)}
      </div>
    </div>
  );
}
PersonList.displayName = 'PersonList';
PersonList.propTypes = {
  persons: personListType.isRequired,
  heading: PropTypes.string,
};
PersonList.defaultProps = {
  heading: 'Personal',
};
