import React from 'react';

import { personType } from './types';
import StaticImage from './StaticImage';

export default function Person({ data }) {
  return (
    <div className="staff-list-item">
      <div className="staff-card clearfix">
        {!!data.post_image && (
          <div className="staff-image search-item-image">
            <StaticImage url={data.post_image} />
          </div>
        )}
        <div className="staff-text search-items-text">
          <div className="staff-titles">
            {!!data.person_name && (
              <h4 className="staff-name search-item-title">
                {data.person_name}
              </h4>
            )}
            {!!data.person_title && (
              <p className="staff-title">{data.person_title}</p>
            )}
          </div>
          <div className="staff-contacts">
            {!!data.person_phone && (
              <p className="staff-phone">{data.person_phone}</p>
            )}
            {!!data.person_email && (
              <p
                className="staff-email"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: data.person_email }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
Person.displayName = 'Person';
Person.propTypes = {
  data: personType.isRequired,
};
