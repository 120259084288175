import React from 'react';
import PropTypes from 'prop-types';

export default function SearchHeader({ children, logoUrl }) {
  return (
    <div className="site-header search-header">
      <div className="site-header-inner search-header-inner">
        <div className="site-logo search-header-logo">
          <img className="site-logo-inner" src={logoUrl} alt="" />
        </div>
        {children}
      </div>
    </div>
  );
}
SearchHeader.displayName = 'SearchHeader';
SearchHeader.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
