import React from 'react';
import PropTypes from 'prop-types';

export default function StaticImage({ alt, url }) {
  return <img src={url} alt={alt} />;
}
StaticImage.displayName = 'StaticImage';
StaticImage.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
};
StaticImage.defaultProps = {
  alt: '',
};
